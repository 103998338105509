import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Toaster } from '@lionstep/ui';
import { createTheme, MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

// Mantine
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import mantineTheme from './mantine/mantineTheme';

// Components
import Root from './containers/Root';
import NotificationsCenter from './components/Notifications/NotificationsCenter';

dayjs.extend(calendar);
dayjs.extend(relativeTime);
dayjs.extend(utc);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const theme = createTheme(mantineTheme);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={theme}>
        <Notifications />
        <div className="font-sans z-10">
          <NotificationsCenter />
          <Root />
          <Toaster position="top-right" />
        </div>
        {process.env.REACT_APP_NODE_ENV === 'qa' && <ReactQueryDevtools />}
      </MantineProvider>
    </QueryClientProvider>
  );
}

export default App;
